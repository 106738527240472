import React, { Component, useState } from 'react';
import { array, bool, func, oneOf, object, shape, string, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { NO_ACCESS_PAGE_USER_PENDING_APPROVAL, parse } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { isErrorUserPendingApproval, isForbiddenError } from '../../util/errors';
import { isUserAuthorized } from '../../util/userHelpers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, H5, NamedRedirect, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
  pickListingFieldFilters,
  omitLimitedListingFieldParams,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import { GridIcon, InfoIcon, ListIcon, LocationIcon } from './assets/Icons';
import { PageTitle } from '../OtherPagesComponents/PageHeadingTitle/PageTitle';
import { Icon } from '../../components/IconClose/IconClose.example';
import { IconAdd } from '../../examples';
import SearchInput from '../LandingPageCustom/BannerComp/SearchInput/SearchInput';
import SearchInputMap from '../LandingPageCustom/BannerComp/SearchInput/SearchInputMap/SearchInputMap';
import CustomeFilter from './FilterComponents/RatingFilter';
import SearchMap from './SearchMap/SearchMap';
import { types as sdkTypes } from '../../util/sdkLoader';
import { setActiveListing } from './SearchPage.duck';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listingView: false,
      isMobileModalOpen: false,
      showFilterForMobile: false,
      activebtn: "grid",

      currentQueryParams: validUrlQueryParamsFromProps(props),
    };

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.onMapMoveEnd = this.onMapMoveEnd.bind(this);
    // Filter functions
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);

    // View
    this.listingView = this.listingView.bind(this);
    this.gridView = this.gridView.bind(this);
  }



   onMapMoveEnd(viewportBoundsChanged, data) {
      const { viewportBounds, viewportCenter } = data;
      
      const routes = this.props.routeConfiguration;
      const searchPagePath = pathByRouteName('SearchPage', routes);
      const currentPath =
        typeof window !== 'undefined' && window.location && window.location.pathname;
  
      // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
      const isSearchPage = currentPath === searchPagePath;
  
      // If mapSearch url param is given
      // or original location search is rendered once,
      // we start to react to "mapmoveend" events by generating new searches
      // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
      if (viewportBoundsChanged && isSearchPage) {
        const { history, location, config } = this.props;
        const { listingFields: listingFieldsConfig } = config?.listing || {};
        const { defaultFilters: defaultFiltersConfig } = config?.search || {};
        const listingCategories = config.categoryConfiguration.categories;
        const filterConfigs = {
          listingFieldsConfig,
          defaultFiltersConfig,
          listingCategories,
        };
  
        // parse query parameters, including a custom attribute named category
        const { address, bounds, mapSearch, ...rest } = parse(location.search, {
          latlng: ['origin'],
          latlngBounds: ['bounds'],
        });
  
        const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
        const dropNonFilterParams = false;
  
        const searchParams = {
          address,
          ...originMaybe,
          bounds: viewportBounds,
          mapSearch: true,
          ...validFilterParams(rest, filterConfigs, dropNonFilterParams),
        };
  
        history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
      }
    }
  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  listingView() {
    this.setState({ listingView: true });
  }

  gridView() {
    this.setState({ listingView: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const listingCategories = config.categoryConfiguration.categories;
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: omitLimitedListingFieldParams(
            {
              ...mergedQueryParams,
              ...updatedURLParams,
              ...keywordsMaybe,
              address,
              bounds,
            },
            filterConfigs
          ),
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      routeConfiguration,
      config,
      onActivateListing,
      activeListingId
    } = this.props;

    const { listingFields } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;
    const categoryConfiguration = config.categoryConfiguration;
    const listingCategories = categoryConfiguration.categories;
    const listingFieldsConfig = pickListingFieldFilters({
      listingFields,
      locationSearch: location.search,
      categoryConfiguration,
    });
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      filterConfigs,
      sortConfig,
      isOriginInUse(config)
    );
    const validQueryParams = urlQueryParams;

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const builtInPrimaryFilters = defaultFiltersConfig.filter(f =>
      ['categoryLevel'].includes(f.key)
    );
    const builtInFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => !['keywords', 'categoryLevel'].includes(f.key))
      : defaultFiltersConfig.filter(f => !['categoryLevel'].includes(f.key));
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const availableFilters = [
      {
        key: 'categoryLevel',
        schemaType: 'category',
        scope: 'public',
        isNestedEnum: true,
        nestedParams: ['categoryLevel1'],
      },
      {
        key: 'price',
        schemaType: 'price',
        label: 'Price',
        min: 0,
        max: 5000000,
        step: 5,
      },
      {
        key: 'ratingFilter',
        schemaType: 'ratingFilter',
        scope: 'public',
        isNestedEnum: true,
        nestedParams: ['categoryLevel1'],
      },
      {
        key: 'searchRadius',
        schemaType: 'searchRadius',
        scope: 'public',
        isNestedEnum: true,
        nestedParams: ['categoryLevel1'],
      },
      {
        key: 'locationClose',
        schemaType: 'locationClose',
        scope: 'public',
        isNestedEnum: true,
        nestedParams: ['categoryLevel1'],
      },
     
    ];

    // Selected aka active filters
    const selectedFilters = validQueryParams;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      filterConfigs
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    // const [listView , setListView] = useState();

    // const listHandel = (()=>{
    //     setListView()
    // })
    const { bounds, origin } = searchParamsInURL || {};
    const { UUID } = sdkTypes;
console.log('location>>>>',searchParamsInURL,location)

const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < 1200;
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer rootClassName={topbarClasses} currentSearchParams={validQueryParams} />
        <div className={css.container}>
          <div className={css.headerContainer}>
            <div className={css.headerWrapper}>
              <PageTitle
                title="Your"
                hightlightText="Listings"
                subLine="Select up to 3 listings to compare"
                subLineIcon={<InfoIcon />}
              />
            </div>
            <div className={css.searcBarM}>
              <SearchInputMap />
            </div>
            {/* <div className={css.searchBarContainer}>
            <div className={css.searchInputWrapper}>
              <LocationIcon />
              <input type="text" name="searchLocation" />
            </div>
            <button type={css.searchButton}>Search</button>
          </div> */}
          </div>
          <div className={css.subHeaderContainer}>
            <div className={css.leftContent}>
              <h3>Your care options</h3>
              <span className={css.resultsFound}>
                {searchInProgress ? (
                  <FormattedMessage id="MainPanelHeader.loadingResults" />
                ) : (
                  <span>Showing 1 - {totalItems} care options</span>
                )}
              </span>
            </div>
            <div className={css.filterIconBlock}>
              <div
                className={css.filterIcon}
                onClick={() => {
                  this.setState({ showFilterForMobile: !this.state.showFilterForMobile });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                  height="25px"
                  width="25px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 210.68 210.68"
                  xmlSpace="preserve"
                >
                  <path d="M205.613,30.693c0-10.405-10.746-18.149-32.854-23.676C154.659,2.492,130.716,0,105.34,0  C79.965,0,56.021,2.492,37.921,7.017C15.813,12.544,5.066,20.288,5.066,30.693c0,3.85,1.476,7.335,4.45,10.479l68.245,82.777v79.23  c0,2.595,1.341,5.005,3.546,6.373c1.207,0.749,2.578,1.127,3.954,1.127c1.138,0,2.278-0.259,3.331-0.78l40.075-19.863  c2.55-1.264,4.165-3.863,4.169-6.71l0.077-59.372l68.254-82.787C204.139,38.024,205.613,34.542,205.613,30.693z M44.94,20.767  C61.467,17.048,82.917,15,105.34,15s43.874,2.048,60.399,5.767c18.25,4.107,23.38,8.521,24.607,9.926  c-1.228,1.405-6.357,5.819-24.607,9.926c-16.525,3.719-37.977,5.767-60.399,5.767S61.467,44.338,44.94,40.62  c-18.249-4.107-23.38-8.521-24.607-9.926C21.56,29.288,26.691,24.874,44.94,20.767z M119.631,116.486  c-1.105,1.341-1.711,3.023-1.713,4.761l-0.075,57.413l-25.081,12.432v-69.835c0-1.741-0.605-3.428-1.713-4.771L40.306,54.938  C58.1,59.1,81.058,61.387,105.34,61.387c24.283,0,47.24-2.287,65.034-6.449L119.631,116.486z" />
                </svg>
              </div>
            </div>
            <div className={css.rightContent}>
              <div className={css.filtersWrapper}>
                <div className={css.sortyByWrapper}>
                  <span className={css.sortyBy}>
                    <FormattedMessage id="MainPanelHeader.sortBy" />
                  </span>
                  {sortBy('desktop')}
                </div>
              </div>
              <div className={css.viewManager}>
                <button
                  className={classNames(css.gridView, { [css.active]: !this.state.listingView })}
                  onClick={this.gridView}
                >
                  <GridIcon />
                </button>
                <button
                  className={classNames(css.listView, { [css.active]: this.state.listingView })}
                  onClick={this.listingView}
                >
                  <ListIcon />
                </button>
              </div>
            </div>
          </div>
          <div className={css.addMapBlock}>
          <div className={css.searchWrapperContainer}>
            <aside
              className={classNames(css.layoutWrapperFilterColumn, {
                [css.showMobileFilter]: this.state.showFilterForMobile,
              })}
              data-testid="filterColumnAside"
            >
              <div className={css.filterColumnContent}>
                <p className={css.filterHeading}>Filter Option</p>
                {availableFilters.map(filterConfig => {
                  const key = `SearchFiltersDesktop.${filterConfig.scope || 'built-in'}.${
                    filterConfig.key
                  }`;
                  console.log('availableFilters',availableFilters)
                  return (
                    <FilterComponent
                      key={key}
                      idPrefix="SearchFiltersDesktop"
                      className={css.filter}
                      config={filterConfig}
                      listingCategories={listingCategories}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      liveEdit
                      showAsPopup={false}
                      isDesktop
                    />
                  );
                })}
                {/* <button className={css.applyFilterButton} onClick={e => this.handleResetAll(e)}>
                <span>Apply Filters</span>
              </button> */}

                <button
                  className={`${css.viewDetailsButton} applyFilterButton  btnBlock btnOutline btnDefault`}
                  onClick={e => this.handleResetAll(e)}
                >
                  Apply Filters
                </button>
              </div>
            </aside>
          
          </div>

          {/* {shouldShowSearchMap ? ( */}
          <div className={css.mapProductsBlock}>
            <div className={css.tabForMobile}>
              <button className={this.state.activebtn === "grid" && css.activeTabB} onClick={()=> this.setState({activebtn:'grid'})}>Products</button>
              <button className={this.state.activebtn === "map" && css.activeTabB} onClick={()=>  this.setState({activebtn:'map'})}>Map</button>
            </div>
            {(this.state.activebtn === "map" || !isMobileLayout) &&
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={false}
                  location={location}
                  listings={listings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              }
              {/* ) : null} */}
             { (this.state.activebtn === "grid" || !isMobileLayout) &&
              <div
              className={classNames(css.listingsForGridVariant, {
                [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
              })}
            >
              {searchListingsError ? (
                <H3 className={css.error}>
                  <FormattedMessage id="SearchPage.searchError" />
                </H3>
              ) : null}
              {!isValidDatesFilter ? (
                <H5>
                  <FormattedMessage id="SearchPage.invalidDatesFilter" />
                </H5>
              ) : null}
              <SearchResultsPanel
                className={css.searchListingsPanel}
                listingView={this.state.listingView}
                listings={listings}
                pagination={listingsAreLoaded ? pagination : null}
                search={parse(location.search)}
                isMapVariant={true}
                onManageDisableScrolling={this.props.onManageDisableScrolling}
                config={config}
                intl={intl}
                onActivateListing={onActivateListing}
                 setActiveListing={onActivateListing}
                
              />
            </div>
  }

              </div>
            
              </div>
        </div>
        <FooterContainer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  activeListingId:null,
  searchListingsError: null,
  searchParams: {},
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,

  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const searchListingsError = props.searchListingsError;
  if (isForbiddenError(searchListingsError)) {
    // This can happen if private marketplace mode is active
    return (
      <NamedRedirect
        name="SignupPage"
        state={{ from: `${location.pathname}${location.search}${location.hash}` }}
      />
    );
  }

  const { currentUser, ...restOfProps } = props;
  const isPrivateMarketplace = config.accessControl.marketplace.private === true;
  const isUnauthorizedUser = currentUser && !isUserAuthorized(currentUser);
  const hasUserPendingApprovalError = isErrorUserPendingApproval(searchListingsError);
  if ((isPrivateMarketplace && isUnauthorizedUser) || hasUserPendingApprovalError) {
    return (
      <NamedRedirect
        name="NoAccessPage"
        params={{ missingAccessRight: NO_ACCESS_PAGE_USER_PENDING_APPROVAL }}
      />
    );
  }

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...restOfProps}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    currentUser,
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onActivateListing: listingId => dispatch(setActiveListing(listingId)),

});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
